import React from "react"
import { Helmet } from "react-helmet"
import Header from "../components/header"
import Footer from "../components/footer"
import { graphql, Link, useStaticQuery } from "gatsby"
import BackgroundImage from "gatsby-background-image"
import "../styles/style.css"

const Contacto = () => {
  const data = useStaticQuery(graphql`
    query {
      image: file(relativePath: { eq: "cabecera-qualoom.jpg" }) {
        id
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    }
  `)
  return (
    <div>
      <Helmet>
        {/*Primary Meta Tags*/}
        <title>Contacto | Qualoom</title>
        <meta name="title" content="Contacto | Qualoom"/>
        <meta name="description" content="Estaremos encantados de atender tus peticiones vía telefónica a través del teléfono que encontrarás en esta página o personalmente en nuestras oficinas."/>
        <html lang='es'/>        
        <link rel="alternate" href="https://www.qualoom.es/contacto/"/>
        <link rel="canonical" href="https://www.qualoom.es/contacto/"/>
        <meta name="ahrefs-site-verification" content="11d569467b4aa0b0c9daa5b49b9f41eb8a413858d36c9f6ece089482f4d3b630"/>
        
        {/*Open Graph / Facebook*/}
        <meta property="og:locale" content="es_ES"/>
        <meta property="og:type" content="website"/>
        <meta property="og:url" content="https://www.qualoom.es/contacto/"/>
        <meta property="og:title" content="Contacto | Qualoom"/>
        <meta property="og:description" content="Estaremos encantados de atender tus peticiones vía telefónica a través del teléfono que encontrarás en esta página o personalmente en nuestras oficinas."/>
        <meta property="og:image" content="https://images.ctfassets.net/wy4ivoo9i8ge/4UakbOBsjwOI5UzcBYMvcJ/3c0f7b57c3dbdd087a22c3277ffc3aa2/tw_cards_contacto.png"/>
        <meta property="og:image:width" content="1200"/>
        <meta property="og:image:height" content="628"/>
        <meta property="fb:admins" content="338114870907726"/>
        
        {/*Twitter*/}
        <meta property="twitter:card" content="summary_large_image"/>
        <meta property="twitter:url" content="https://www.qualoom.es/contacto/"/>
        <meta property="twitter:title" content="Contacto | Qualoom"/>
        <meta property="twitter:description" content="Estaremos encantados de atender tus peticiones vía telefónica a través del teléfono que encontrarás en esta página o personalmente en nuestras oficinas."/>
        <meta property="twitter:image" content="https://images.ctfassets.net/wy4ivoo9i8ge/4UakbOBsjwOI5UzcBYMvcJ/3c0f7b57c3dbdd087a22c3277ffc3aa2/tw_cards_contacto.png"/>
        <meta property="twitter:creator" content="https://twitter.com/qualoom"/>

        <script type="application/ld+json">
        {`{"@type": "BreadcrumbsList", "@id":"https://www.qualoom.es/contacto/#breadcrumb",              
            "itemListElement":[
              {"@type":"ListItem", "position":"1", "name":"Inicio", "item": {"@id": "https://www.qualoom.es/", "name": "Inicio"}},
              {"@type":"ListItem", "position":"2", "name":"Contacto", "item": {"@id": "https://www.qualoom.es/contacto/", "name": "Contacto"}}
            ]}`}
        </script>
      </Helmet>
      <BackgroundImage fluid={data.image.childImageSharp.fluid}>
        <div className="background-contacto">
          <div className="header_div"><Header /></div>
          <div className="main">
            <div className="empty"></div>
            <div className="text">
              <h1>Esperamos poder conocerte</h1>
              <p>Estaremos encantados de atender tus peticiones. De manera adicional, si necesitas contactar con nosotros vía telefónica, puedes hacerlo a través del teléfono que encontrarás en esta página o personalmente en nuestras oficinas.</p>
            </div>
          </div>
        </div>
      </BackgroundImage>
      <div className="contact-main">
        <h2>Encuentra nuestras oficinas en Madrid</h2><hr></hr>
        <p>Te informamos de que tus datos personales serán tratados por Qualoom para atender tu consulta y poder ponernos en contacto contigo. Puedes ejercer tus derechos de protección de datos escribiéndonos un email a <a href="mailto:contacto@qualoom.es">contacto@qualoom.es</a>.</p>
        <p>Más información en nuestra <Link to="/politica-de-privacidad/">política de privacidad</Link>.</p><br/>
        <div className="contact-content">
          <div className="contact-details">
            <div className="details">
              <p><b>Siempre disponibles para ayudarte</b></p>
              <ol>
                <li>Plaza de Valencia 6 Bajo Local</li>
                <li>Móstoles, Madrid 28937</li>
                <li>España</li><br/>
                <li>Teléfono: <a href="tel:+34912364808">(+34) 91 236 4808</a></li>
                <li>E-mail: <a href="mailto:contacto@qualoom.es">contacto@qualoom.es</a></li>
              </ol>
            </div>
          </div>
          <div className="map"><iframe title="ubicación oficinas" src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3041.781559399747!2d-3.8599559854180914!3d40.32500526913839!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0xd418c284a8d294f%3A0x867058335efc8016!2sPlaza%20Valencia%2C%206%2C%2028937%20M%C3%B3stoles%2C%20Madrid%2C%20Spain!5e0!3m2!1sen!2s!4v1592153005087!5m2!1sen!2s"width="90%" height="320" frameborder="0" allowFullScreen="" aria-hidden="false"></iframe></div>
        </div>
      </div>
      <Footer />
    </div>
  )
}

export default Contacto
